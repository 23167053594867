import React, { useEffect, useState } from 'react';
import LuckyCalImage from '../../images/events/LuckyNumberCalendar.jpg';
import { Container, Row, Col, Button, Card, Carousel, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LuckyNumbersService from '../../services/LuckyNumbersService';
import { DateTime } from 'luxon';

function LuckyCalendarPage() {

  const luckyNumberService = new LuckyNumbersService();
  const [luckyNumInfo, setLuckyNumInfo] = useState<any>({});

  const getLuckyNumbers = async () => {
    const lnResp = await luckyNumberService.getLuckyNumbers();
    setLuckyNumInfo(lnResp?.data);
  }

  const formatISODate = (isoDate: string, format: string) => {
    const dt = DateTime.fromISO(isoDate);
    return dt.isValid ? dt.toFormat(format) : isoDate;;
  }

  useEffect(() => {
    getLuckyNumbers();
  }, []);

  return (
    <>
      <Container>
        <Row className="p-2" flex>
          <Col md="12"><h2 className="d-flex justify-content-center">2025 Lucky Number Calendar</h2></Col>
        </Row>
        <Row flex>
          <Col md="3" />
          <Col md="6" className="gap-2">

            {luckyNumInfo.currentDate && <Carousel variant="dark" indicators={false}>
              <Carousel.Item interval={12000}>
                <Card>
                  <Card.Header className="d-flex justify-content-center text-primary"><h4>{formatISODate(luckyNumInfo.currentDate, "cccc, LLLL dd, yyyy")}</h4></Card.Header>
                  <Card.Body>
                    <Card.Text className="d-flex justify-content-center">
                      <span className="text-primary fs-1"><Badge bg="primary">{luckyNumInfo.currentNumber}</Badge></span>

                    </Card.Text>
                    <Card.Footer className="d-flex justify-content-center fs-xxs">Next Drawing, {formatISODate(luckyNumInfo.nextDate, "LLLL dd, yyyy")}</Card.Footer>
                  </Card.Body>
                </Card>
              </Carousel.Item>

              {luckyNumInfo.luckyNumbers?.map((lrec: any, index: number) => 
                (index >= 1 && index <= 5) && <Carousel.Item interval={2700}>
                  <Card>
                    <Card.Header className="d-flex justify-content-center"><h4>{formatISODate(lrec?.date, "cccc, LLLL dd, yyyy")}</h4></Card.Header>
                    <Card.Body>
                      <Card.Text className="d-flex justify-content-center primary">
                        <h1 className="text-primary"><Badge bg="secondary">{lrec?.number}</Badge></h1>
                      </Card.Text>
                      <Card.Footer className="d-flex justify-content-center fs-6">If you won, expect payment in 1-2 weeks</Card.Footer>
                    </Card.Body>
                  </Card>
                </Carousel.Item>
              )}

            </Carousel>}
          </Col>
          <Col md="3" />
        </Row>
        <Row className="p-2" flex>
          <Col md="9" className="gap-2">
            <h3 className="d-flex justify-content-start text-primary">How does it Work?</h3>
            <ul className="fs-4">
              <li>It works like a raffle that happens every day!</li>
              <li>By purchasing a calendar ($30), you will receive a number ranging from 000 to 999</li>
              <li>Each day of the week in 2025, except Sundays, you have a chance of winning $50 if your number is drawn.</li>
              <li>On a special day each month, you could win $100.</li>
              <li>We will use the <u>evening</u> Ohio Pick 3 number as our random number for the raffle. This ensures that the number is truly random.</li>
              <li>If you win, we will send your money to you within 1 to 2 weeks. If payment doesn't arrive, please contact us at <a href="mailto://kofc1056.luckycalendar@gmail.com">kofc1056.luckycalendar@gmail.com</a></li>
              <li>We only ask for your name, address, email & phone so that we can send you the money if you win. We will not sell or distribute this data.</li>
              <li>Many people give these as gifts. On the purchase screen you'll have the option to provided the name / address for each calendar you purchase. This is where the money will be sent if their lucky number is drawn.</li>
              <li>All of the profits will go to St Mary Catholic Church.</li>
            </ul>
            <br />
            <Link to="/purchasecalendar"><Button variant="primary" size="lg">Purchase Lucky Calendar</Button></Link>
            <br /><br />
            For questions, please email <a href="mailto://kofc1056.luckycalendar@gmail.com">kofc1056.luckycalendar@gmail.com</a>
          </Col>
          <Col lg="3" className="gap-2">
            <img src={LuckyCalImage} className="img-fluid rounded" alt="Lucky Number Calendar" /><br /><br />
            <Link to="/purchasecalendar"><Button variant="primary" size="lg">Purchase Lucky Calendar</Button></Link>
          </Col>
        </Row>
        <Row className="p-5" flex>
          <Col md="12">&nbsp;</Col>
        </Row>
      </Container>
    </>


  );
}

export default LuckyCalendarPage;