
import { DateTime } from 'luxon';


class LuckyNumbersService {


  public async purchase(data: any): Promise<any> {

    const host = `https://api.kofc1056.org/api`;
    //const host = `http://localhost:3002`;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

    const res = await fetch(`${host}/luckynumbers/purchase`, requestOptions);
    console.log("Response", res);
    const purchResponse: any = await res?.json();

    return purchResponse;
  }

  public async getLuckyNumbers(): Promise<any> {

    const host = `https://api.kofc1056.org/api`;
    //const host = `http://localhost:3002`;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    const res = await fetch(`${host}/luckynumbers`, requestOptions);
    console.log("Response", res);
    const luckyNumbersResponse: any = await res?.json();

    return luckyNumbersResponse;
  }

}

export default LuckyNumbersService;